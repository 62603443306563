import React, { useState, useEffect } from 'react';
import { getters } from 'redux/store';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';

import HeaderBar from 'components/header-bar';

const OrderDetail = ({ info }) => {
  // 先使用 useSelector 讀取整個狀態
  const state = useSelector((state) => state);
  // 然後根據條件選擇使用哪個 getter
  const data =
    info === 'refund'
      ? getters.getRefundDetail(state)
      : getters.getOrderDetail(state);

  const [updatePage, setUpdatePage] = useState(false);
  const [detailData, setDetailData] = useState({});

  useEffect(() => {
    setDetailData(data);
    setUpdatePage(true);
  }, []);

  useEffect(() => {
    return () => {
      setUpdatePage(false);
    };
  }, [updatePage]);

  return (
    <>
      <div className="header-bar">
        <HeaderBar title="其他資訊" goBack={() => navigate(-1)} />
      </div>
      {!updatePage && (
        <div className="page">
          <div className="item-box">
            <h2>收件資料</h2>
            <p className="content">
              {`${detailData.recipient ?? ''} ${
                detailData.customer_phone ?? ''
              }`}
              <br />
              {`${detailData.zipcode ?? ''} ${
                detailData.shipping_address ?? ''
              }`}
              <br />
              {detailData.shipping_model_id === '1'
                ? `${detailData.elevator_type_name ?? ''} ${
                    detailData.receive_type_name ?? ''
                  }`
                : undefined}
            </p>
          </div>
          <div className="item-box">
            <h2>配送方式</h2>
            <p className="content">
              物流方式：{detailData.shipping_model_name ?? ''}
              <br />
              出貨門店：{detailData.from_store_name ?? ''}
              <br />
              {detailData.shipping_model_id === '1'
                ? undefined
                : `取貨地址：${detailData.to_store_address ?? ''}`}
              {detailData.shipping_model_id === '1' ? undefined : <br />}
              配送時間：
              {info === 'refund'
                ? `${detailData.shipping_date ?? ''} ${
                    detailData.shipping_time ?? ''
                  }`
                : `${detailData.shipping_date ?? ''} ${
                    detailData.expected_shipping_time ?? ''
                  }`}
            </p>
          </div>
          <div className="item-box">
            <h2>付款方式</h2>
            <p className="content">
              {`${detailData.ecorder_payment} ${
                detailData.payment_status ?? ''
              }`}
              <br />
              {detailData.real_payment_date &&
              detailData.real_payment_date !== '' &&
              detailData.real_payment_time &&
              detailData.real_payment_time !== ''
                ? `付款時間：${detailData.real_payment_date ?? ''} ${
                    detailData.real_payment_time ?? ''
                  }`
                : ''}
              <br />
              {detailData.credit_card && detailData.credit_card !== ''
                ? `卡號：${detailData.credit_card ?? ''}`
                : ''}
            </p>
          </div>
          <div className="item-box">
            <h2>發票資訊</h2>
            <p className="content">
              發票號碼：
              {detailData.invoice_no !== ''
                ? detailData.invoice_no
                : detailData.invoice_status}
              <br />
              發票隨機碼：{detailData.invoice_random_no}
              <br />
              發票方式：{detailData.invoice_type ?? ''}
              <br />
              統一編號：{detailData.uniform_numbers ?? ''}
              <br />
              發票日期：
              {info === 'refund'
                ? `${detailData.invoicing_date ?? ''} ${
                    detailData.invoice_no_time ?? ''
                  }`
                : `${detailData.invoice_apply_date ?? ''} ${
                    detailData.invoice_apply_time ?? ''
                  }`}
              <br />
              發票金額：
              {info === 'refund'
                ? detailData.invoice_amounts ?? ''
                : detailData.invoice_no_amount ?? ''}
            </p>
          </div>
          {/* <h2>遇缺貨時的處理方式</h2> */}
          <p className="content" hidden>
            請以同系列款式的其他品項或者其他品牌同性質商品替代(結賬價格相同)
          </p>{' '}
          <div className="item-box no-hr">
            <h2>備註</h2>
            <p className="content">
              {detailData.shipping_address_note || '無'}
            </p>
          </div>
        </div>
      )}
      <style jsx>{`
        .header-bar {
          width: 100%;
          z-index: 1;
          position: fixed;
          top: 0;
        }

        .page {
          height: 100%;
          padding-top: 55px;
          padding-bottom: 70px;
          position: relative;

          .item-box {
            padding: 17px 0px 6px 41px;
            border-bottom: 1px solid #d6d6d6;

            &.no-hr {
              border-bottom: none;
            }

            h2 {
              font-family: 'Noto Sans TC';
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 26px;
              color: #8185cf;
              margin-bottom: 8px;
            }

            .content {
              font-family: 'Noto Sans TC';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 30px;
              color: #333333;
            }
          }
        }
      `}</style>
    </>
  );
};

export default OrderDetail;
